.siteBtn{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight:500;
    border-radius:10px;
    border-color: rgb(230,230, 230);
    border-style: solid;
}

.siteBtn:hover {
    opacity:0.5;
    cursor:pointer;
   }